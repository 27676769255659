<template>
  <v-footer dark padless>
    <v-card flat tile class="grey darken-3 white--text text-center " width  = "100%">
      <v-card-text>
        <v-btn class="mx-4 white--text" href="https://www.instagram.com/hubdesignperu/" target="_blank" icon>
          <v-icon size="24px" >
            mdi-instagram
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — Hub Design
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>