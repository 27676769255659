<template>
  <div class="grey lighten-4">
    <v-container fluid fill-height class = "pb-0">
      <v-layout align-center justify-center >
        <v-flex >
          <v-card
            align="center"
            class="d-flex align-center mb-6 mx-auto"
            max-width="700"
            tile
            flat
            color="transparent"
          >
            <v-container fluid class="pa-16 pb-10">
              <h1 class="text-h4 black--text font-weight-medium mb-4">
                Tenemos para ti
              </h1>
              <p class="text-h6 black--text font-weight-light">
                Visita las diferentes marcas que se encuentran disponibles en
                nuestro espacio.
              </p>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fuild style="max-width: 1250px" class = "pb-16">
      <v-row>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://instagram.com/agapanthus.peru?utm_medium=copy_link" target="_blank">
            <v-img
              height="277"
              src="../assets/cd09abe1-1092-4b50-bc50-e986dd46af7e 1.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              AGAPANTHUS
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Paisajismo y mantenimiento de jardines
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://instagram.com/dekomundo_pe?utm_medium=copy_link" target="_blank">
            <v-img
              height="277"
              src="../assets/1-2 1.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              DEKO MUNDO
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Acabados decorativos para interiores y exteriores
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.larbis.net/" target="_blank">
            <v-img
              height="277"
              src="../assets/Kronberg_BRELG167.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              LARBIS
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Calefacción radiante de interiores y exteriores
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.instagram.com/mueblum/" target="_blank">
            <v-img
              height="277"
              src="../assets/me11711620_all_747980 1.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              MUEBLUM
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Soluciones en herrajes y accesorios para muebles
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.rollux.pe/" target="_blank">
            <v-img
              height="277"
              src="../assets/Rollux.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              ROLLUX
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Soluciones de control solar y decoración de ventanas 
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.instagram.com/leven.arq/" target="_blank">
            <v-img
              height="277"
              src="../assets/Captura de Pantalla 2022-04-20 a la(s) 08.45 1.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              LEVEN
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Texturas profesionales para proyectos de arquitectura
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.ventanasyestilos.com" target="_blank">
            <v-img
              height="277"
              src="../assets/IMG_20210930_115519 1.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              VYE
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Ventanería hermética de PVC y sistemas de protección solar
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.instagram.com/somosvivirbonito/?hl=es" target="_blank">
            <v-img
              height="277"
              src="../assets/6 - VB 1.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              VIVIRBONITO
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Macetas decorativas para interiores y exteriores
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" max-width="277" align="center" href="https://www.instagram.com/heliosperu/?hl=es" target="_blank">
            <v-img
              height="277"
              src="../assets/helios.png"
            >
            </v-img>
            <v-card-title class="text-h6 black--text justify-center pt-5">
              HELIOS
            </v-card-title>
            <v-card-text class="font-weight-light black--text pb-7">
              Soluciones profesionales en iluminación
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "TenemosParaTi",
};
</script>