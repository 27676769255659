<template>
  <div class="grey lighten-4">
    <v-container fluid fill-height class="pb-0">
      <v-layout align-center justify-center>
        <v-flex>
          <v-card
            align="center"
            class="d-flex align-center mb-6 mx-auto"
            max-width="850"
            tile
            flat
            color="transparent"
          >
            <v-container fluid class="pa-16 pb-10">
              <h1 class="text-h4 black--text font-weight-medium mb-4">
                Explora nuestro ambiente
              </h1>
              <p class="text-h6 black--text font-weight-light">
                Buscamos crear una experiencia inspiracional que a la vez contribuya de manera práctica a la creación de proyectos innovadores.
              </p>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fuild style="max-width: 1400px" class="pb-16">
      <v-sheet class="mx-auto">
        <v-slide-group light multiple show-arrows class = "grey lighten-4">
          <v-slide-item class="mx-3">
            <v-img
                height="280"
                width="280"
                src="../assets/IMG_6413 1.png"
              >
              </v-img>
          </v-slide-item>
          <v-slide-item class="mx-3">
            <v-img
                height="280"
                width="280"
                src="../assets/IMG_6416 1.png"
              >
              </v-img>
          </v-slide-item>
          <v-slide-item class="mx-3">
            <v-img
                height="280"
                width="280"
                src="../assets/IMG_6419 1.png"
              >
              </v-img>
          </v-slide-item>
          <v-slide-item class="mx-3">
            <v-img
                height="280"
                width="280"
                src="../assets/IMG_6445 1.png"
              >
              </v-img>
          </v-slide-item>
          <v-slide-item class="mx-3">
            <v-img
                height="280"
                width="280"
                src="../assets/IMG_6442 1.png"
              >
              </v-img>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "ExploraNuestroAmbiente",
};
</script>