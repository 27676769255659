<template>
  <v-parallax src="../assets/IMG_3070 copia 1.png" height="301">
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12" >
        <h1 class="text-h6 white--text font-weight-light mb-4">Encuéntranos en</h1>
        <h1 class="text-h4 white--text font-weight-medium mb-4">Av. General Mendiburu 686</h1>
        <h1 class="text-h4 white--text font-weight-medium mb-4">Miraflores</h1>
      </v-col>
    </v-row>
  </v-parallax>
</template>
<script>
export default {
  name: "Encuentranos",
};
</script>