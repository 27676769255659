<template>
  <v-parallax src="../assets/fondo.png" height="652">
    <v-row align="center" justify="center">
      <v-col class="text-left mx-auto" cols="12">
        <v-container fluid style="margin: 0px; padding: 0px;max-width: 620px">
          <h1 class="text-h3 black--text font-weight-light mb-4">Diferentes soluciones <strong>en un solo lugar</strong></h1>
          <v-layout wrap>
            <div class="pt-6">
              <p class="text-h6 black--text font-weight-light">
                Un espacio con el objetivo de congregar a las marcas más representativas de diseño para hacer de tus proyectos algo único.
              </p>
            </div>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
  </v-parallax>
</template>
<script>
export default {
  name: "HelloWorld",
};
</script>

