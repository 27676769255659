import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "323232", // #E53935
                secondary: "E5E5E5", // #FFCDD2
                accent: "000000", // #3F51B5
                background: "323232",
            },
        },
        dark: {
            primary: "323232", // #E53935
            secondary: "E5E5E5", // #FFCDD2
            accent: "000000", // #3F51B5
            background: "323232",
        },
    },
});
