<template>
  <div class="grey lighten-4">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex>
          <v-card
            align="center"
            class="d-flex align-center mb-6 mx-auto"
            max-width="700"
            tile
            flat
            color="transparent"
          >
            <v-container fill-height fluid>
              <v-container fluid class="pa-16 pb-5">
                <h1 class="text-h4 black--text font-weight-light mb-4">
                  Todo para tus proyectos
                </h1>
              </v-container>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container fuild style="max-width: 800px" >
      <v-row>
        <v-col sm="3">
          <v-card class="white mx-auto" outlined align="center">
            <v-img
              class="shrink mt-9"
              contain
              src="../assets/diseno-de-interiores 1-4.png"
              width="45"
            />
            <p class="text-h6 black--text font-weight-light mt-5">
              Interiorismo
            </p>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" outlined align="center">
            <v-img
              class="shrink mt-7"
              contain
              src="../assets/plano 1.png"
              width="44"
            />
            <p class="text-h6 black--text font-weight-light mt-5">
              Arquitectura
            </p>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" outlined align="center">
            <v-img
              class="shrink mt-7"
              contain
              src="../assets/pantone 1.png"
              width="46"
            />
            <p class="text-h6 black--text font-weight-light mt-5">
              Acabados
            </p>
          </v-card>
        </v-col>
        <v-col sm="3">
          <v-card class="white mx-auto" outlined align="center">
            <v-img
              class="shrink mt-7"
              contain
              src="../assets/arquitectura 1.png"
              width="46"
            />
            <p class="text-h6 black--text font-weight-light mt-5">
              Construcción
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "TodoParaTusProyectos",
};
</script>