<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">
    <v-app-bar
      app
      color="rgb(50, 50, 50, 0.7)"
    >
      <div class="d-flex align-center md-4 pl-8">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/hub logo.svg"
          width="112"
        />
      </div>

      <v-spacer></v-spacer>
      
    </v-app-bar>

    <v-main>
      <Welcome/>
      <TodoParaTusProyectos/>
      <TenemosParaTi/>
      <Encuentranos/>
      <ExploraNuestroAmbiente/>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Welcome from './components/Welcome';
import TodoParaTusProyectos from './components/TodoParaTusProyectos';
import TenemosParaTi from './components/TenemosParaTi';
import Encuentranos from './components/Encuentranos';
import ExploraNuestroAmbiente from './components/ExploraNuestroAmbiente';
import Footer from './components/Footer';

export default {
  name: 'App',

  components: {
    Welcome,
    TodoParaTusProyectos,
    TenemosParaTi,
    Encuentranos,
    ExploraNuestroAmbiente,
    Footer
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  data: () => ({
    //
  }),
};
</script>

